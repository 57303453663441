<template>
  <div class="text-center">
    <!-- <table class="inner-table" v-if="false">
      <tbody>
        <tr>
          <td>
            <el-tooltip effect="dark" content="English" placement="top">
              <b>
                {{ escore }}
              </b>
            </el-tooltip>
          </td>
          <td class="M_score">
            <el-tooltip effect="dark" content="Math" placement="top">
              <div>
                <b>{{ mscore }}</b>
              </div>
            </el-tooltip>
          </td>
        </tr>
        <tr>
          <td colspan="4" class="T_score">
            <span>
              <el-tooltip
                v-if="userExamId > 0"
                effect="dark"
                content="Total"
                placement="top"
              >
                <b>
                  <router-link
                    :to="{
                      name: 'SATResult',
                      params: {
                        id: userExamId
                      },
                      query: {
                        practiceType: 'composePractices'
                      }
                    }"
                  >
                    {{ tscore }}
                  </router-link>
                </b>
              </el-tooltip>
              <b v-else>
                {{ tscore }}
              </b>
            </span>
          </td>
        </tr>
      </tbody>
    </table> -->
    <el-tooltip effect="dark" content="English" placement="top" v-if="escore">
      <b>
        {{ escore }}
        +
      </b>
    </el-tooltip>
    <el-tooltip effect="dark" content="Math" placement="top" v-if="mscore">
      <b>
        {{ mscore }}
        =
      </b>
    </el-tooltip>
    <el-tooltip
      effect="dark"
      :content="total ? 'Correct/Total' : 'Score'"
      placement="top"
    >
      <b>
        <router-link
          v-if="userExamId > 0"
          :to="{
            name: 'SATResult',
            params: {
              id: userExamId
            },
            query: {
              practiceType: practiceType
            }
          }"
        >
          {{ tscore }}<span v-if="total">/{{ total }} </span>
        </router-link>
        <span class="text-success" v-else>
          {{ tscore }}<span v-if="total">/{{ total }} </span>
        </span>
      </b>
    </el-tooltip>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["escore", "mscore", "tscore", "userExamId", "practiceType", "total"],
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["profile", "token"])
  },
  watch: {},

  mounted() {},

  methods: {
    setExam() {
      this.$emit("setExam");
    },
    removeExam() {
      this.$emit("removeExam");
    }
  }
};
</script>

<style scoped>
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.inner-table {
  text-align: center;
  width: 100%;
  height: 50%;
  border: 1px #333;
}
table.inner-table tr th,
table.inner-table tr td {
  border: none;
}
.table td .M_score {
  border-left: 1px solid #e6e9ed;
}
.table-bordered th,
.table-bordered td {
  border-left: none;
}
.table td .T_score {
  border-top: 1px solid #e6e9ed;
}
.T_score b {
  font-size: 18px;
}
.R_score,
.G_score,
.E_score,
.M_score,
.M_score b,
.T_score b {
  color: #444;
}
.inner-table b {
  font-size: 18px;
}
.inner-table .T_score b {
  font-size: 20px;
}
.T_score {
  cursor: pointer;
}
.score-edit {
  display: none;
}
.T_score:hover .score-edit {
  cursor: pointer;
  display: inline-block;
}
</style>
